.homeBanner-wrapper {
  width: 100%;
  background-size: cover !important;
  background-position: center center !important;
}

.homeBanner-wrapper .homeBanner-content {
  max-width: 900px;
  padding: 50px;
  text-align: center;
}

.slider-left {
  text-align: left;
  margin: auto 0 !important;
  background-position: left;
}

.slider-right {
  text-align: right;
  margin: auto 0 !important;
  flex-direction: row-reverse !important;
  background-position: right;
}

.slider-light>* {
  color: #fff !important;
  border-color: #fff !important;
}