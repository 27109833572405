@media only screen and (min-width: 900px) {
    .signup-option-step {
        background: url(//meteor.stullercloud.com/das/116366814?scl=1.1&fmt=png) no-repeat center / cover, linear-gradient(to top, rgba(3, 3, 3, 0.3) 0%, rgba(3, 3, 3, 0) 100%), url(//meteor.stullercloud.com/das/52699161?scl=1&$sharpenpng$) repeat left / contain;
    }
}

@media only screen and (max-width: 899px) {
    .signup-option-step {
        background: url(//meteor.stullercloud.com/das/52699161?scl=1&$sharpenpng$) repeat left;
        background-size: auto;
    }
}