@media (max-width: 1199px) {
    .legal-wrapper {
        padding-left: 2rem !important;
        padding-right: 2rem !important
    }
}
.legal-wrapper .MuiPaper-root {
    background-color: #fafafa;
}
.legal-wrapper .MuiPaper-root:hover {
    box-shadow: 0px 5px 5px 0px #4c5a6333;
}
.legal-ul {
    color: #555;
    font-weight: 300;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    line-height: 1.5;
    padding-inline-start: 20px;
  } 
  .legal-ul > li {
    margin-bottom: 8px;
    font-size: 17px;
  }
  .legal-topic-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    word-break: break-word;
  }
  .legal-topic-content a {
    width: 100%;
    padding: 2rem;
    text-decoration: none;
  }