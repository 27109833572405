.btn-navbar,
.iconbtn-navbar {
    color: #fff !important;
    background-color: transparent !important;
    border-radius: 0 !important;
    height: 48px;
    min-width: 48px;
    opacity: .8;
}

.btn-navbar {
    padding: 8px 16px !important;
}

.btn-navbar:hover,
.iconbtn-navbar:hover {
    background-color: transparent !important;
    transition: opacity .2s;
    opacity: 1;
}


.menu-item-navbar {
    color: #fff !important;
    font-weight: 300 !important;
    font-size: .9rem !important;
    opacity: .7;
    transition: opacity .2s;
}

.menu-item-navbar:hover {
    background-color: transparent !important;
    opacity: 1;
}

.menu-hamburger {
    font-size: 1rem !important;
    font-weight: 400 !important;
}

.link-hamburger {
    min-height: 36px !important;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    text-decoration: none !important;
}