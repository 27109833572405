.footer-wrapper {
  background: #eee;
  font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
}

.footer-link-list {
  padding-top: 2rem !important;
  padding-bottom: 1.5rem !important;
}

.footer-link-list li:first-child {
  padding-top: 0px;
  padding-bottom: 10px;
}

.footer-link-list li {
  padding-top: 2px;
  padding-bottom: 2px;
}

.footer-link-list a {
  font-size: 13px;
  color: #666;
  text-decoration: none;
  transition: all 0.1s ease-in;
}

.footer-text-list a {
  margin-left: 5px;
}

.footer-link-list p.title {
  font-size: 14px;
  font-weight: 400;
}

.footer-link-list a:hover {
  font-weight: 400;
  color: #222;
}

.footer-text-list li {
  color: #555;
}

.footer-text-list .icon {
  margin-right: 5px;
}

.postfooter-wrapper {
  font-weight: 400;
  color: #555;
}

.bottomfooter-wrapper {
  background: #333;
}

.bottomfooter-content {
  font-size: 13px;
  font-weight: 300;
  color: #ccc;
}

.bottomfooter-content a,
.bottomfooter-content a:visited {
  color: #ccc;
  text-decoration: none;
  transition: all .1s ease-in;
  font-weight: 300;
}

.bottomfooter-content a:hover {
  color: #fff;
}

.bottomfooter-content .separator {
  margin: 0 8px;
  opacity: .5;
}

.bottomfooter-content .copyright {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 13px;
  font-weight: 300;
}

.footer-title {
  font-size: 14px !important;
  font-weight: 400 !important;
}