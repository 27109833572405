* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: var(--mainColor);
  cursor: pointer;
  transition: all .5s ease;
  font-weight: 400;
}

a:hover {
  text-decoration: underline;
}

.videowrapper {
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.videowrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;

}