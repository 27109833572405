.megamenu-title {
    font-size: .9rem !important;
    font-weight: 500;
    color: #fff;
    padding: 0 1rem;
}

.megamenu-tab {
    color: #fff !important;
    text-transform: capitalize !important;
    text-align: left;
    align-items: flex-start !important;
    min-height: 32px !important;
    opacity: .6;
    transition: opacity .2s;
    padding: 0 1rem !important;
}

.megamenu-tab:hover {
    opacity: 1;
    text-decoration: none;
}

.Mui-selected {
    opacity: 1;
    border-radius: 4px !important;
    color: black !important;
    background: #eee !important;
}